<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationword">
      {{cvWordLabel}}</label
    >
    <input
      v-model="vmGAME_CWFormData.word"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationdescription">
      {{cvDescriptionLabel}}</label
    >
    <input
      v-model="vmGAME_CWFormData.description"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="CrossWordAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { CrossWords } from "../../../FackApi/api/game_cw"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "GameCwAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add game_cw",
      cvCardSubHeader: "Add game_cw ",
      cvSubmitBtn: "Add",
      cvWordLabel: "word",
      cvDescriptionLabel: "description",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GAME_CW Add Response",
      vmGAME_CWFormData: Object.assign({}, this.initFormData()),
      vmGAME_CWDesc: null,
      vmGAME_CWType: null
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "word": "",
        "description": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGAME_CWFormData) {
          if (!this.vmGAME_CWFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * CrossWordAdd
     */
    async CrossWordAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gameCwAddResp = await CrossWords.CrossWordAdd(this, this.vmGAME_CWFormData)
        await ApiResponse.responseMessageDisplay(this, gameCwAddResp)
        if (gameCwAddResp && !gameCwAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGameCwAddModal", gameCwAddResp.resp_data)
        }
        this.vmGAME_CWFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at CrossWordAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
